import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["truncated", "full", "toggle"]

  toggle(event) {
    event.preventDefault()
    if (this.fullTarget.style.display === "none") {
      this.fullTarget.style.display = "inline"
      this.truncatedTarget.style.display = "none"
      this.toggleTarget.textContent = "Read less"
    } else {
      this.fullTarget.style.display = "none"
      this.truncatedTarget.style.display = "inline"
      this.toggleTarget.textContent = "Read.."
    }
  }
}